/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import axios from 'axios';  
import React from 'react';  
import Card from "@mui/material/Card";
import { useLocation, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
// @mui material components
import SoftTypography from "components/SoftTypography";

import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React components
import team2 from "assets/images/team-2.jpg";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import gr_logo from "assets/images/group.png";
import { FaDollarSign } from "react-icons/fa";


// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftButton from "components/SoftButton";

//function Schedules() {

  function Match({is_auction, remaining_slots,  total_participants,status_name,is_draft, groupname, series_name, match_date, match_time, match_link, is_admin,series_id,group_id,show_edit,group_logo, noGutter }) {
    return (
      <SoftBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor="grey-100"
        borderRadius="lg"
        p={3}
        mb={noGutter ? 0 : 1}
        mt={2}
      >
        <SoftBox width="100%" display="flex" flexDirection="column">
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            <SoftAvatar
              src={group_logo ? (group_logo):(gr_logo)}
              alt="profile-image"
              variant="rounded"
              size="sm"
              shadow="sm"
            />

            <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {groupname}
            </SoftTypography>
  
            <SoftBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >

            {
                  (() => {
                    if(is_admin == "1" && show_edit=="1"){

                      var link_to_create = "/makeauction";
                  var querystring = "?id="+btoa(group_id)+"&sid="+btoa(series_id);
  var auc_color = "dark";
  if(is_auction=='1')
  {
    auc_color = 'info';
  }
             return ( 
              <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'createlink'}} }>

             <SoftButton variant="text" color={auc_color}>
                            <FaDollarSign></FaDollarSign>
            &nbsp;Auction
              </SoftButton>
              </Link>

             );
                    }

})()
        
}


              {
                  (() => {
                    if(is_admin == "1" && show_edit=="1"){

                      var link_to_create = "/viewmembers";
                  var querystring = "?id="+btoa(group_id)+"&sid="+btoa(series_id);


             return ( 
              <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'createlink'}} }>

             <SoftButton variant="text" color="dark">
                <Icon>edit</Icon>&nbsp;edit
              </SoftButton>
              </Link>

             );
                    }

})()
        
}

{
                  (() => {
                    if(is_admin == "1" && show_edit=="1"){

                      var link_to_create = "/viewmembers";
                  var querystring = "?id="+btoa(group_id)+"&csid="+btoa(series_id);


             return ( 
              <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'copylink'}} }>

             <SoftButton variant="text" color="dark">
                <Icon>copy</Icon>&nbsp;Copy
              </SoftButton>
              </Link>

             );
                    }

})()
        
}

            </SoftBox>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" color="text">
               Name:&nbsp;&nbsp;&nbsp;
               <a href={match_link} target="_blank">
              <SoftTypography variant="caption" color="info" fontWeight="medium" textTransform="capitalize">
                {series_name}
              </SoftTypography>
              </a>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" color="text">
              Date:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontWeight="medium">
                {match_date}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftTypography variant="caption" color="text">
            Time:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {match_time}
            </SoftTypography>
          </SoftTypography>

          <SoftTypography variant="caption" color="text">
            Total Slots:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {total_participants}
            </SoftTypography>
          </SoftTypography>

          <SoftTypography variant="caption" color="text">
            Remaining Slots:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
              {remaining_slots}
            </SoftTypography>
          </SoftTypography>

          <SoftTypography variant="caption" color="text">
            Status:&nbsp;&nbsp;&nbsp;
            <SoftTypography variant="caption" fontWeight="medium">
            {status_name}|{is_draft}
            </SoftTypography>
          </SoftTypography>

        </SoftBox>
      </SoftBox>
    );
  }
  function ScheduleList({listitems})
  {
    
          
      {
        const rows = [];
        for (let i = 0; i < listitems.length; i++) {
          console.log(listitems[i])
            // note: we are adding a key prop here to allow react to uniquely identify each
            // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
            rows.push(<Match groupname={listitems[i].group_name} key={i} />);
        }
        return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
      }
      {/*
      <Match
      name="oliver liam"
      company="viking burrito"
      email="oliver@burrito.com"
      vat="FRB1235476"
    />
    <Match
      name="lucas harper"
      company="stone tech zone"
      email="lucas@stone-tech.com"
      vat="FRB1235476"
    />
    <Match
      name="ethan james"
      company="fiber notion"
      email="ethan@fiber.com"
      vat="FRB1235476"
      noGutter
    />
    </SoftBox>      
      );  */}
  }
export default class Schedules extends React.Component {  

  constructor(props) {
    super(props);

    this.state = { showModal: false,viewstatus:'list'};

    this.setState({showModal: false});
    //this.setState({ upcoming_schedules: false });  
    //this.setState({ past_schedules:false});  

  }


  componentDidMount() {  

    const post_data = { lo : 1};

    axios.post( global.config.app.backend_api_url + `login/sessionavailable`,post_data, { withCredentials: true }
    )  
      .then(res => {  
        console.log(res.data)
        var user_id = res.data.user_details.user_id;
        
        if (user_id !== undefined && user_id !== null && user_id !== 'null') 
        {
        }else
        {
          window.location.replace('sign-in');
          //return;
        }
        
        
      });

      let search1 = window.location.search;
      let params1 = new URLSearchParams(search1);
      let group_id1 = '';

      if(params1.get('g')!=null)
      {
        group_id1 = atob(params1.get('g'));
      }
      

      
      const post_data1 = { lo : 1,group_id:group_id1};
//alert(group_id1)
    axios.post(global.config.app.backend_api_url +`matchschedule/getmatchschedules`,post_data1, { withCredentials: true })  
      .then(res => {  
        //const posts = res.data;  
        //const posts = this.createRows(res.data);
        console.log(res.data)

        //const upcoming_schedules = this.createSchedules(res.data.upcoming);
        console.log("getting response "+res.data.normal_members)
        this.setState({ upcoming_schedules:res.data.upcoming });  

        //const past_schedules = this.createSchedules(res.data.past);
        console.log("getting response ")
        this.setState({ past_schedules:res.data.past });  

        if(res.data.user_details.user_id!='')
        {
          this.callNotificationSubscribe(res.data.user_details.nai,res.data.user_details.user_id);
        }

        //this.setState({ posts });  
      });
      /*
      axios.get(`http://localhost:9000/testAPIp`, { withCredentials: true })  
      .then(res => {  
        this.setState({loggedIn: res.data.loggedIn, designation: "your value"});

      });*/

  }  

  callNotificationSubscribe(appid,userid)
  {
    if(userid=='' || appid=='')
    {
      return;
    }
    const s = document.createElement("script")
    s.src = "https://notix.io/ent/current/enot.min.js"
    s.onload = (sdk) => {
        sdk.startInstall({
            appId: appid,
            loadSettings: true,
            disableConsoleDebug: true,
            user: userid
        });

    }
    document.head.append(s);
  }




  render() {  

    return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={4}>
      {
          (() => {
          let search1 = window.location.search;
          let params1 = new URLSearchParams(search1);
          let group_id1 = '';
    
          if(params1.get('g')!=null)
          {
            group_id1 = atob(params1.get('g'));
          }

          if(group_id1!='')
          {

            var link_to_create = "/viewmembers";
            var querystring = "?id="+btoa(group_id1);

            return ( <Link to={{pathname:link_to_create,search:querystring,state:{viewstatus: 'list'}} }>

<SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                    onClick={() => this.setState({viewstatus: 'list',selected_group:''})}
                  >
                    Back to group
                  </SoftTypography>
                
             </Link>
            )
          }
        })()
        }
        <SoftBox my={3}>
        
        
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                    <Card id="delete-account">
              <SoftBox pt={3} px={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Upcoming Matches
                </SoftTypography>
              </SoftBox>
              <SoftBox pt={1} pb={2} px={2}>
                {
                  (() => {
                  const listitems = this.state.upcoming_schedules;
                  console.log(listitems);
                  console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                  var draft_name = listitems[i].is_draft;
                  if(draft_name=='0')
                  {
                    draft_name = 'Published';
                  }
                  else
                  {
                    draft_name = 'Draft';
                  }
                  rows.push(<Match is_auction={listitems[i].is_auction} status_name={listitems[i].status_name} is_draft={draft_name} remaining_slots={listitems[i].remaining_slots}  total_participants={listitems[i].total_participants}  show_edit='1' series_id={listitems[i].series_id}  group_id={listitems[i].group_id}  is_admin={listitems[i].is_admin}  match_link={listitems[i].invite_link} groupname={listitems[i].group_name} group_logo={listitems[i].group_logo} series_name={listitems[i].series_name} match_date={listitems[i].match_date} match_time={listitems[i].from_time} key={i} />);
              }
              return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }
               
              </SoftBox>
            </Card>


            <Card id="delete-account">
              <SoftBox pt={3} px={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Past Matches
                </SoftTypography>
              </SoftBox>
              <SoftBox pt={1} pb={2} px={2}>
                {
                  (() => {
                  const listitems = this.state.past_schedules;
                  console.log(listitems);
                  console.log("populating")
                  if (typeof(listitems) != "undefined")
                  {
                  console.log(listitems)
              const rows = [];
              for (let i = 0; i < listitems.length; i++) 
              {
                var draft_name = listitems[i].is_draft;
                if(draft_name=='0')
                {
                  draft_name = 'Published';
                }
                else
                {
                  draft_name = 'Draft';
                }


                  rows.push(<Match show_edit='0' is_auction={listitems[i].is_auction} status_name={listitems[i].status_name} is_draft={draft_name} remaining_slots={listitems[i].remaining_slots} show_edit='1' total_participants={listitems[i].total_participants}   series_id={listitems[i].series_id}  group_id={listitems[i].group_id}  is_admin={listitems[i].is_admin}  match_link={listitems[i].invite_link} groupname={listitems[i].group_name}  group_logo={listitems[i].group_logo} series_name={listitems[i].series_name} match_date={listitems[i].match_date} match_time={listitems[i].from_time} key={i} />);
              }
              return <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>{rows}</SoftBox>;
            }

            })()
        
          }
               
              </SoftBox>
            </Card>

            </Grid>
            
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

}  

