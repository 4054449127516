/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import HomePage from "layouts/home";
import Tables from "layouts/tables";
import Groups from "layouts/groups";
import MakeAuction from "layouts/makeauction";
import Viewmembers from "layouts/viewmembers";
import Attendance from "layouts/attendance";



import Mi from "layouts/mi";
import Miupdatepay from "layouts/miupdatepay";
import Miplain from "layouts/mi/plain";

import Playersprint from "layouts/mi/playersprint";

import Auction from "layouts/auction";
import TeamPlayers from "layouts/teamplayers";


import Matchschedules from "layouts/matchschedules";
import Liveauction   from "layouts/liveauction";
import Liveauctionclone   from "layouts/liveauction/clone";


import Schedules from "layouts/schedules";
import TermsAndConditions from "layouts/termsandconditions";
import PrivacyPolicy from "layouts/privacypolicy";

import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ForgotMyPassword from "layouts/authentication/forgot-my-password";
import NotFound from "NotFound";
// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";

const routes = [
  {
    type: "",
    name: "Home",
    key: "",
    route: "/",
    icon: <Shop size="12px" />,
    component: <HomePage />,
    noCollapse: true,
  }/*,
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  }*/,
  {
    type: "",
    name: "My Groups",
    key: "tables",
    route: "/tablesrerewrwer",
    icon: <Office size="12px" />,
    component: <Tables />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "My Groups",
    key: "groups",
    route: "/groups",
    icon: <Office size="12px" />,
    component: <Groups />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Set Auction",
    key: "makeauction",
    route: "/makeauction",
    icon: <Office size="12px" />,
    component: <MakeAuction />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Live Auction",
    key: "liveauction",
    route: "/liveauction",
    icon: <Office size="12px" />,
    component: <Liveauction />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Live Auction",
    key: "liveauction",
    route: "/liveauction/clone",
    icon: <Office size="12px" />,
    component: <Liveauctionclone />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Match Invitation",
    key: "mi",
    route: "/mi",
    icon: <Office size="12px" />,
    component: <Mi />,
    noCollapse: true,
  },

  

  {
    type: "",
    name: "View Group Members",
    key: "view members",
    route: "/viewmembers",
    icon: <Office size="12px" />,
    component: <Viewmembers />,
    noCollapse: true,
  },{
    type: "",
    name: "View Group Members Attendance",
    key: "view attendance",
    route: "/attendance",
    icon: <Office size="12px" />,
    component: <Attendance />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Match Participants Update Payment",
    key: "match invitation",
    route: "/miupdatepay",
    icon: <Office size="12px" />,
    component: <Miupdatepay />,
    noCollapse: true,
  },
  

  
  {
    type: "",
    name: "View Match Schedules",
    key: "view schedules",
    route: "/matchschedules",
    icon: <Office size="12px" />,
    component: <Matchschedules />,
    noCollapse: true,
  },
  

  {
    type: "collapse",
    name: "Match Schedules",
    key: "schedules",
    route: "/schedules",
    icon: <CreditCard size="12px" />,
    component: <Schedules />,
    noCollapse: true,
  },
  
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    route: "/sign-up",
    icon: <SpaceShip size="12px" />,
    component: <SignUp />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Forgot my password",
    key: "forgot-my-password",
    route: "/forgot-my-password",
    icon: <SpaceShip size="12px" />,
    component: <ForgotMyPassword />,
    noCollapse: true,
  },
  

  {
    type: "",
    name: "Terms and Conditions",
    key: "",
    route: "/termsandconditions",
    icon: <Shop size="12px" />,
    component: <TermsAndConditions />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Privacy Policy",
    key: "",
    route: "/privacypolicy",
    icon: <Shop size="12px" />,
    component: <PrivacyPolicy />,
    noCollapse: true,
  },{
    type: "",
    name: "Match Participants Invitation",
    key: "match invitation",
    route: "/mi/plain",
    icon: <Office size="12px" />,
    component: <Miplain />,
    noCollapse: true,
  },{
    type: "",
    name: "Team Players",
    key: "teamplayers",
    route: "/teamplayers",
    icon: <Office size="12px" />,
    component: <TeamPlayers />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Print Match Participantion List",
    key: "match invitation print",
    route: "/mi/playersprint",
    icon: <Office size="12px" />,
    component: <Playersprint />,
    noCollapse: true,
  },
   

  ,{
    type: "",
    name: "Auction",
    key: "auction",
    route: "/auction",
    icon: <Office size="12px" />,
    component: <Auction />,
    noCollapse: true,
  },
  {
    type: "",
    name: "404",
    key: "",
    route: "*",
    icon: <Shop size="12px" />,
    component: <NotFound />,
    noCollapse: true,
  }
  

];

export default routes;
