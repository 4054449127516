import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Grid, Card, Box } from "@mui/material";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import team2 from "assets/images/team-2.jpg";
import "auction.css"; // External CSS for styles
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

const spinData = [
  { option: "0", style: { backgroundColor: "green", color: "white" } },
  { option: "1", style: { backgroundColor: "red", color: "white" } },
];

const Player = ({ sl_no, player_name, auction_sold_status }) => (
  <SoftBox mb={1}>
    <SoftTypography variant="caption" color="info" fontWeight="medium">
      {sl_no}. {player_name}
      {auction_sold_status === 2 && <span style={{ color: 'red' }}>*</span>}
    </SoftTypography>
  </SoftBox>
);

const Team = ({ team_name, team_logo = team2, players, linktoteam }) => (
  <Grid xs={12} md={12} className="team-container">
    <SoftBox display="flex" p={1}>
      <SoftAvatar src={team_logo} alt="team-logo" variant="rounded" size="sm" shadow="sm" />
      <Link to={`/teamplayers?t=${linktoteam}`}>{team_name}</Link>
    </SoftBox>
    <PlayerTable players={players} />
  </Grid>
);

const PlayerTable = ({ players = [] }) => (
  <table className="player-table">
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Sold Amount</th>
        <th>PDF Sl No</th>
      </tr>
    </thead>
    <tbody>
      {players.map((player, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{player.option}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const LiveAuction = () => {
  const [auctionData, setAuctionData] = useState({
    main_auction_details: {},
    teams_details: [],
    sold_players: [],
    pending_players: [],
  });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter < 40) { // 40 intervals = 20 minutes
        callBasicApi();
        setCounter((prev) => prev + 1);
      } else {
        clearInterval(intervalId);
        if (window.confirm('Restart updates?')) {
          setCounter(0); // Reset counter to start again
        }
      }
    }, 30000); // Refresh every 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [counter]);

  const callBasicApi = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const invite_code = params.get('i');
      const post_data = { invite_code };

      const res = await axios.post(
        `${global.config.app.backend_api_url}auction/get_current_auction_status_by_invite_code/`,
        post_data,
        { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
      );

      if (res.data.status === 0) {
        console.log(res.data.message);
        return;
      }

      const { main, teams, sold, pending } = res.data.auction_details;

      setAuctionData({
        main_auction_details: main[0],
        teams_details: teams,
        sold_players: sold,
        pending_players: pending,
      });
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  return (
    <DashboardLayout>
      <Helmet>
        <title>Live Auction</title>
      </Helmet>
      <DashboardNavbar />
      <Box p={3}>
        <Team
          team_name="Sample Team"
          players={spinData}
          linktoteam="sample-team"
        />
      </Box>
      <Footer />
    </DashboardLayout>
  );
};

export default LiveAuction;
